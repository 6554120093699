<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title" v-if="type === 'edit'">
                <p>序号：{{xuhao}}</p>
            </div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                    <div class="con-item">
                        <a-form-item label="费款属期">
                            <a-input v-model:value="feikuanshuqi" placeholder="请输入费款属期"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="缴费标志">
                            <a-input v-model:value="jiaofeibiaozhi" placeholder="请输入缴费标志"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期参保人数">
                            <a-input v-model:value="benqicanbaorenshu" placeholder="请输入本期参保人数"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期应缴合计金额">
                            <a-input v-model:value="benqiyingjiaohejijine" placeholder="请输入本期应缴合计金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期实缴合计金额">
                            <a-input v-model:value="benqishijiaohejijine" placeholder="请输入本期实缴合计金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期欠缴合计金额">
                            <a-input v-model:value="benqiqianjiaohejijine" placeholder="请输入本期欠缴合计金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期应退合计金额">
                            <a-input v-model:value="benqiyingtuihejijine" placeholder="请输入本期应退合计金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期实退合计金额">
                            <a-input v-model:value="benqishituihejijine" placeholder="请输入本期实退合计金额"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期在职缴费基数">
                            <a-input v-model:value="benqizaizhijiaofeijishu" placeholder="请输入本期在职缴费基数"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期单位缴费基数">
                            <a-input v-model:value="benqidanweijiaofeijishu" placeholder="请输入本期单位缴费基数"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期个人应缴">
                            <a-input v-model:value="benqigerenyingjiao" placeholder="请输入本期个人应缴"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期单位应缴">
                            <a-input v-model:value="benqidanweiyingjiao" placeholder="请输入本期单位应缴"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="补收个人应缴">
                            <a-input v-model:value="bushougerenyingjiao" placeholder="请输入补收个人应缴"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="补收单位应缴">
                            <a-input v-model:value="bushoudanweiyingjiao" placeholder="请输入补收单位应缴"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期个人实缴">
                            <a-input v-model:value="benqigerenshijiao" placeholder="请输入本期个人实缴"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期单位实缴">
                            <a-input v-model:value="benqidanweishijiao" placeholder="请输入本期单位实缴"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="补收个人实缴">
                            <a-input v-model:value="bushougerenshijiao" placeholder="请输入补收个人实缴"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="补收单位实缴">
                            <a-input v-model:value="bushoudanweishijiao" placeholder="请输入补收单位实缴"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="本期退收合计金额">
                            <a-input v-model:value="benqituishouhejijine" placeholder="请输入本期退收合计金额"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </a-form>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" style="margin-right: 20px;" @click="onSave">保存</a-button>
            <a-button type="primary" @click="$router.back()">取消</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'loginType',
            'isAuth',
            'orgId',
            'y'
        ])
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            id: '',
            xuhao: '',
            type: '',
            feikuanshuqi: '',
            jiaofeibiaozhi: '',
            benqicanbaorenshu: '',
            benqiyingjiaohejijine: '',
            benqishijiaohejijine: '',
            benqiqianjiaohejijine: '',
            benqiyingtuihejijine: '',
            benqishituihejijine: '',
            benqizaizhijiaofeijishu: '',
            benqidanweijiaofeijishu: '',
            benqigerenyingjiao: '',
            benqidanweiyingjiao: '',
            bushougerenyingjiao: '',
            bushoudanweiyingjiao: '',
            benqigerenshijiao: '',
            benqidanweishijiao: '',
            bushougerenshijiao: '',
            bushoudanweishijiao: '',
            benqituishouhejijine: ''
        }
    },
    methods: {
        // 获取养老保险详情
        getYLBXDetail () {
            this.$store.dispatch('getYLBXDetail', { SHEBAODATA_YANGLAO_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    let datas = res.pd
                    this.feikuanshuqi = datas.FEIYONGSHUQI
                    this.jiaofeibiaozhi = datas.JIAOFEIBIAOZHI
                    this.benqicanbaorenshu = datas.BENQICBRS
                    this.benqiyingjiaohejijine = datas.BQYJHEJE
                    this.benqishijiaohejijine = datas.BQSJHEJE
                    this.benqiqianjiaohejijine = datas.BQQJHJJE
                    this.benqiyingtuihejijine = datas.BQYTHJJE
                    this.benqishituihejijine = datas.BQSTHJJE
                    this.benqizaizhijiaofeijishu = datas.BQZZJFJS
                    this.benqidanweijiaofeijishu = datas.BQDWJFJS
                    this.benqigerenyingjiao = datas.BQGRYJ
                    this.benqidanweiyingjiao = datas.BQDWJY
                    this.bushougerenyingjiao = datas.BSGRYJ
                    this.bushoudanweiyingjiao = datas.BSDWYJ
                    this.benqigerenshijiao = datas.BQGRSJ
                    this.benqidanweishijiao = datas.BQDWSJ
                    this.bushougerenshijiao = datas.BSGRSJ
                    this.bushoudanweishijiao = datas.BSDWSJ
                    this.benqituishouhejijine = datas.BQTSHJJE
                } else {
                    this.$message.error('获取养老保险详情失败，请稍后重试！')
                }
            })
        },
        onSave () {
            if (this.type === 'edit') {
                this.onEdit()
            } else if (this.type === 'add') {
                this.onAdd()
            }
        },
        // 添加养老保险
        onAdd () {
            this.$store.dispatch('addYLBXItem', {
                YEAR: this.y,
                ENTERPRISE_ID: this.orgId,
                FEIYONGSHUQI: this.feikuanshuqi,
                JIAOFEIBIAOZHI: this.jiaofeibiaozhi,
                BENQICBRS: this.benqicanbaorenshu,
                BQYJHEJE: this.benqiyingjiaohejijine,
                BQSJHEJE: this.benqishijiaohejijine,
                BQQJHJJE: this.benqiqianjiaohejijine,
                BQYTHJJE: this.benqiyingtuihejijine,
                BQSTHJJE: this.benqishituihejijine,
                BQZZJFJS: this.benqizaizhijiaofeijishu,
                BQDWJFJS: this.benqidanweijiaofeijishu,
                BQGRYJ: this.benqigerenyingjiao,
                BQDWJY: this.benqidanweiyingjiao,
                BSGRYJ: this.bushougerenyingjiao,
                BSDWYJ: this.bushoudanweiyingjiao,
                BQGRSJ: this.benqigerenshijiao,
                BQDWSJ: this.benqidanweishijiao,
                BSGRSJ: this.bushougerenshijiao,
                BSDWSJ: this.bushoudanweishijiao,
                BQTSHJJE: this.benqituishouhejijine,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('添加成功！')
                    setTimeout(() => {
                        this.$router.push('/ReportForms/YLBX')
                    }, 1500)
                } else {
                    this.$message.error('添加失败，请稍后重试！')
                }
            })
        },
        // 编辑养老保险
        onEdit () {
            this.$store.dispatch('editYLBXItem', {
                YEAR: this.y,
                ENTERPRISE_ID: this.orgId,
                FEIYONGSHUQI: this.feikuanshuqi,
                JIAOFEIBIAOZHI: this.jiaofeibiaozhi,
                BENQICBRS: this.benqicanbaorenshu,
                BQYJHEJE: this.benqiyingjiaohejijine,
                BQSJHEJE: this.benqishijiaohejijine,
                BQQJHJJE: this.benqiqianjiaohejijine,
                BQYTHJJE: this.benqiyingtuihejijine,
                BQSTHJJE: this.benqishituihejijine,
                BQZZJFJS: this.benqizaizhijiaofeijishu,
                BQDWJFJS: this.benqidanweijiaofeijishu,
                BQGRYJ: this.benqigerenyingjiao,
                BQDWJY: this.benqidanweiyingjiao,
                BSGRYJ: this.bushougerenyingjiao,
                BSDWYJ: this.bushoudanweiyingjiao,
                BQGRSJ: this.benqigerenshijiao,
                BQDWSJ: this.benqidanweishijiao,
                BSGRSJ: this.bushougerenshijiao,
                BSDWSJ: this.bushoudanweishijiao,
                BQTSHJJE: this.benqituishouhejijine,
                SHEBAODATA_YANGLAO_ID: this.id,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('修改成功！')
                    setTimeout(() => {
                        this.$router.push('/ReportForms/YLBX')
                    }, 1500)
                } else {
                    this.$message.error('修改失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
        this.xuhao = this.$route.query.num
        this.type = this.$route.query.type
        if (this.type === 'edit') {
            this.getYLBXDetail()
        }
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
    }
}
</style>